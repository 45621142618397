.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 60px;

    @media (max-width: 992px) {
        margin-top: 44px;
    }
    &--arrow {
        svg {
            width: 30px;
            height: 30px;
            
            &:hover {
                path { fill: #FAD224; }
            }
            path { 
                fill: #282B37;
                transition: all .3s ease-in-out;
            }
        }
        &.prev { rotate: 90deg; }
        &.next { rotate: -90deg; }
    }
    &--nav {
        ul {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-page {
                display: grid;
                place-items: center;

                background-color: #f5f5f5;
                color: #576370;
                font-size: 17px;
                font-weight: 600;
                width: 40px;
                aspect-ratio: 1;
                transition: all .3s ease-in-out;

                &.currentPage {
                    background-color: #fad224;
                    color: #282B37;

                    &:hover {
                        background-color: #fad224;
                    }
                }
                &:hover {
                    background-color: #EEEFF1;
                }
            }
        }
    }
}
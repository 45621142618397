html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

.gscrollbar-fixer{
    margin: 0 !important;
    padding: 0 !important;
}

html, body{
  & {
    scrollbar-width: auto;
    scrollbar-color: $yellow $black500;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $yellow;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black500;
    border-radius: 0;
    border: 3px solid $black500;
  }
}

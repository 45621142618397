.success-message-forms{
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    z-index: 10000;
    display: none;
    opacity: 1;
    &.open-modal{
        display: flex;
    }    

    &--container{
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        z-index: 1;
    
        width: Min(819px, 70%);
        height: 718px;
        background-color: #242424;
        padding-inline: 20px;
    
        display: grid;
        place-items: center;

        #btn--close-success-message{
            position: absolute;
            top: 32px;
            right: 32px;
            
            .bi {
                font-size: 32px;
                color: #fff;
            }
        }
        .wrapper {
            max-width: 434px;
            text-align: center;
    
            & > img {
                width: 80px;
                aspect-ratio: 1;
                margin-inline: auto;
            }
            .title {
                margin-block: 20px 30px;
            }
        }
    }
}
.scroll {
    width: 100%;
}

.text-marquee {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    z-index: 50;
    .text-single {
        position: relative;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        will-change: transform;
        overflow: hidden;
    }

    .text {
        padding: 0;
        display: inline-block;
        margin-right: 2rem;
        @include fontTemplate(8rem, 1.3, #fff, $medium, $plus, left);
        text-shadow: 2px 2px 0 #242424, -2px 2px 0 #242424, -2px -2px 0 #242424, 2px -2px 0 #242424;
    }

    @media (max-width: 599px) {
        .text {
            font-size: 5rem;
            line-height: 1.35;
            text-shadow: 1.2px 1.2px 0 #242424, -1.2px 1.2px 0 #242424, -1.2px -1.2px 0 #242424, 1.2px -1.2px 0 #242424;
        }
    }
}

#footer{
    padding-top: 75px;
    background: #000;
    padding-inline: 10rem;
    overflow: hidden;

    @media (max-width: 1100px) {
        padding-top: 40px;
        padding-inline: 20px;
    }
    .logo-center{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
        margin-bottom: 27px;
        @media (max-width: 1100px) {
            width: 50%;
            justify-content: start;
            margin-top: 60px;
            margin-bottom: 20px;

            img {
                height: 38px;
                width: 165px;

                @media (max-width: 380px) {
                    height: 30px;
                    width: 126px;
                }
            }
        }
        .selo{
            position: absolute;
            right: 0;
            img{
                width: 250px;
                height: auto;
            }
            @media (max-width: 1100px) {
                right: -40%;
                img{
                    width: 140px;
                }
            }
            @media (max-width: 450px) {
                right: calc(-100% - 45px);
                top: calc(100% + 20px);
            }
        }
    }
    .footer_grid{
        display: grid;
        grid-template-columns: 1fr 1fr 322px;
        padding-top: 25px;
        padding-bottom: 25px;
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;

        @media (max-width: 1100px) {
            grid-template-columns: 1fr;
            gap: 40px;
            padding-top: 40px;
            padding-bottom: 60px;
        }
        &--title{
            @include fontTemplate(1.8rem, 1, $white, $medium, $raleway, start);
            grid-column: 1 / 3;

            @media (max-width: 1100px) {
                font-size: 18px;
                line-height: 21.13px;
            }
        }
        &--link{
            @include fontTemplate(1.4rem, 1.6, $white, $medium, $raleway, start);
            transition: $transition;
            position: relative;
            text-decoration: none;
            width: fit-content;

            @media (max-width: 1100px) {
                font-size: 14px;
                line-height: 16.44px;
            }
            &::after{
                content: '';
                position: absolute;
                width: 0;
                height: 1px;
                background: $yellow;
                bottom: 0;
                left: 0;
                transition: $transition;
            }
            &:hover{
                &::after{
                    width: 100%;
                }
            }

        }
        &--colun-1{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 7rem;
                row-gap: 2.2rem;

                @media (max-width: 1100px) {
                    gap: 61px;
                    row-gap: 20px;
                }
                div{
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;

                    @media (max-width: 1100px) {
                        gap: 12px;
                    }
                }
        }
        &--colun-2{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 7rem;
            row-gap: 2.2rem;

            @media (max-width: 1100px) {
                gap: 61px;
                row-gap: 20px;
            }
            div{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                @media (max-width: 1100px) {
                    gap: 12px;
                }
            }
        }
        &--colun-3{
            display: flex;
            flex-direction: column;
            gap: 2.2rem;

            @media (max-width: 1100px) {
                gap: 61px;
                row-gap: 20px;
            }
            div{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                @media (max-width: 1100px) {
                    gap: 20px;
                }
            }
        }
    }
    .footer_bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 14px;
        padding-bottom: 20px;
        &--text{
            @include fontTemplate(1.4rem, 1, $white, $medium, $raleway, start);

            @media (max-width: 1100px) {
                line-height: 16.44px;

                &:last-of-type {
                    display: none;
                }
            }
        }
        .weecom{
            transition: $transition;
            svg{
                transition: $transition;

                @media (max-width: 1100px) {
                    opacity: .5;
                }
                path{
                    fill: #F5EEE5;
                }
            }
            &:hover{
                svg{
                    opacity: 1;

                    path{
                        fill: $yellow;
                    }
                }
            }
        }
    }
    .footer_top{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        @media (max-width: 1100px) {
            flex-direction: column;
            justify-content: start;
        }
        &--title{
            @include fontTemplate(4.6em, 1, $white, $medium, $plus, start);
            letter-spacing: -2%;

            @media (max-width: 1100px) {
                font-size: 30px;
                line-height: 36px;
            }
        }
        &--sub{
            @include fontTemplate(1.6rem, 1.8rem, $white, $medium, $raleway, start);

            @media (max-width: 1100px) {
                font-size: 12px;
                line-height: 28px;
            }
        }
        &--input{
            @include fontTemplate(1.4rem, 1.8rem, $white, $medium, $raleway, start);
            background: transparent;
            display: flex;
            gap: 24px;
            margin-top: 20px;
            margin-bottom: 10px;
            align-items: center;

            @media (max-width: 1100px) {
                flex-direction: column;
                gap: 12px;
                margin-bottom: 12px;
            }
            input{
                padding-inline: 8px;
                width: 100%;
                border: 1px solid $white;
                background: transparent;
                max-width: 504px;
                height: 43px;
                border-radius: 0;

                @media (max-width: 1100px) {
                    max-width: 100%;
                    height: 41px;
                }
                &, &::placeholder{
                    @include fontTemplate(1.4rem, 1.8rem, $white, $medium, $raleway, start);
                }
            }
            button{
                @include fontTemplate(1.6rem, 1, $black500, $bold, $rex, start);
                text-decoration: none;
                background: $yellow;
                height: 43px;
                padding-inline: 25px;
                display: flex;
                justify-content: center;
                width: fit-content;
                align-items: center;
                transition: $transition;
                text-transform: uppercase;
                text-wrap: nowrap;

                @media (max-width: 1100px) {
                    width: 100%;
                }
                &:hover{
                    color: $yellow;
                    background: $black500;
                }
            }
        }
        &--redes{
            display: flex;
            gap: 20px;
            flex-direction: column;

            @media (max-width: 1100px) {
                position: absolute;
                right: 20px;
                top: 130%;
            }
            span{
                @include fontTemplate(1.6rem, 1.8rem, $white, $medium, $raleway, start);

                @media (max-width: 1100px) {
                    display: none;
                }
            }
            div{
                display: flex;
                gap: 31px;

                @media (max-width: 1100px) {
                    gap: 20px;
                }
                .change{
                    transition: $transition;
                }
                a{
                    @media (max-width: 1100px) {
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    &:hover{
                        .change{
                            fill: $yellow;
                        }
                    }
                }
            }
        }
    }
    /* The checkWrapper */
            .checkWrapper {
                display: block;
                position: relative;
                padding-left: 35px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                @include fontTemplate(1.1rem, 14px, $white, $medium, $raleway, start);
                padding-top: 8px;
                margin-bottom: 12px;
                a{
                    color: $yellow;
                }
                @media (max-width: 1100px) {
                    padding-top: 0;
                    margin-bottom: 0;
                }
            }

            /* Hide the browser's default checkbox */
            .checkWrapper input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #fff;
                border-radius: 50%;
            }

            /* On mouse-over, add a grey background color */
            .checkWrapper:hover input ~ .checkmark {
                background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            .checkWrapper input:checked ~ .checkmark {
                background-color: $yellow;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */
            .checkWrapper input:checked ~ .checkmark:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            .checkWrapper .checkmark:after {
                left: 9px;
                top: 5px;
                width: 7px;
                height: 12px;
                border: solid white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }

}

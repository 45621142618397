/*------------------------- Containers -----------------------*/

.termsfeed-com---nb-simple{
    left: inherit;
    right: 20vw;
    bottom: 2vh;
    transition: 0.4s;
}

/*-------------------------TEXTS -----------------------*/

/*------------------------ BUTTONS -----------------------*/

.termsfeed-com---palette-dark .cc-nb-okagree{
    background: #FAD224;
    color: #1e1e1e;
    margin-right: 1rem !important;
    transition: background-color 1s;
    &:hover{
        background-color: #FAD224;
    }
}
.termsfeed-com---palette-dark .cc-nb-reject{
    color: #1e1e1e;
    background: linear-gradient(141deg, #FAD224 31.89%, transparent) #FAD224 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border: 1px solid #1e1e1e;
    &:hover{
        background-color: #FAD224;
    }
}
.termsfeed-com---palette-dark .cc-nb-changep{
    color: #1e1e1e;
    background: linear-gradient(141deg, #FAD224 31.89%, transparent) #FAD224 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border: 1px solid #1e1e1e;
}
.termsfeed-com---palette-dark .cc-nb-okagree{
    color: #1e1e1e;
    background: linear-gradient(141deg, #FAD224 31.89%, transparent) #FAD224 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border: 1px solid #1e1e1e;
}
.termsfeed-com---nb-simple{
    bottom: 2vh;
    border: 0.2px solid #FAD224;
    transition: 0.4s;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    &::after{
    content: "";
    height: 3px;
    background-color: #FAD224;
    position: absolute;
    left: 0;
    bottom: 0;
    }
}
.header {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0px;
    background: transparent linear-gradient(180deg, #000000 0%, #01050f00 100%)
        0% 0% no-repeat padding-box;
    z-index: 1000;
    height: fit-content;
    transition: 1s;
    &.active {
        background: #485CE6 !important;
        left: inherit;
        right: 0;
        width: calc(100% - 25px);
        padding-bottom: 42px;
        .header--logo{
            filter: brightness(0) invert(1);
        }
    }
    &.activeDown{
        background: #000008;
    }
    &--logoLink{
        @media (max-width: 1200px) {
            width: 120px;
        }
    }
    .form-select{
        width: 84px;
        background-color: transparent;
        border-radius: 0;
        color: #fff;
        height: 48px;
        @include fontTemplate(1.6rem, 1, #fff, 500, $raleway);
        padding-left: 2rem;
        --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.5 5.83032L0.5 0.830322H10.5L5.5 5.83032Z' fill='white'/%3e%3c/svg%3e");
        background-position: right 1.75rem center;
        option{
            background: $black500;
        }
        @media (max-width: 1200px) {
            width: 71px;
            height: 34px;
            font-size: 1.2rem;
            line-height: 1.4rem;
            --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg width='7.5' height='3.75' viewBox='0 0 7.5 3.75' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.75 3.75L0 0H7.5L3.75 3.75Z' fill='white'/%3e%3c/svg%3e");
            z-index: 3;
            background-position: right 1rem center;
            background-size: auto;
        }
    }
    .language-mobile {
        .form-select {
            @media (max-width: 1200px) {
                width: 71.5px;
                padding: 10px 8px;

                option {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }
    &--container {
        display: flex;
        align-items: center;
        gap: 50px;
        justify-content: space-between;
        width: Min(100%, 1539px);

        @media (max-width: 992px) {
            gap: 30px;
        }
    }

    &--logo {
        width: 100%;
        height: 56px;
        object-fit: contain;
    }
    .wrapper-dropdown{
        display: flex;
        flex-direction: column;
        gap: 21px;
    }
    .service-list{
        max-height: 80vh;
        min-width: 865px;
        gap: 100px;
        grid-template-columns: 300px 1fr;
        width: auto;
    }
    .service-dropdown{
        @media (max-width: 1500px) {
            &:hover{
                transform: translate(-33%, 100%)  !important;
            }
        }
    }
    .contato-dropdown{
        @media (max-width: 1500px) {
            &:hover{
                transform: translate(-82%, 100%)  !important;
            }
        }
    }
    .service-wrapper{
        display: grid;
        grid-template-columns: 260px 326px;
        gap: 123px;
    }
    .wrapper-dropdonw{
        li{
            a{
                color: #fff !important;
            }
        }
    }
    .cta-header{
        display: flex;
        flex-direction: column;
        justify-content: end;
        height: 100%;
        
        img{
            width: 210px;
            height: 150px;
            object-fit: cover;
            margin-top: 50px;
        }
        &--title{
            margin-top: 44px;
            margin-bottom: 15px;
            @include fontTemplate(4.8rem, 1, $white, $bold, $raleway, start);
        }
        &--text{
            @include fontTemplate(1.6rem, 1, $white, $medium, $raleway, start);
            margin-bottom: 0;
            margin-top: 20px;
            @media (max-width: 1599px) {
                font-size: 2rem;
            }
        }
        &--link{
            @include fontTemplate(2rem, 1, #485CE6 !important, $bold, $raleway, center);
            text-decoration: none;
            padding: 20px 40px;
            margin-top: 44px;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            gap: 8px;
            border-radius: 5px;
            transition: all 0.3s ease-in;
            align-items: center;
            &:hover{
                background-image: linear-gradient(118deg, #fff -15%, #dfe4fd 59%, #dfe4fd 59%);
            }
        }
    }
    &--nav {
        width: 100%;
        .active{
            &::before{
                width: 100%;
            }
        }
        &_list {
            margin-bottom: 0;
            display: grid;
            grid-template-columns: .5fr 237px .5fr;
            justify-content: space-between;
            align-items: center;
        }
        &_inside{
            display: flex;
            gap: 40px;
            align-items: center;

            &:last-of-type {
                justify-content: end;
            }
        }
        &_item {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            height: fit-content;
            &::after{
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-left: 25px solid transparent;
                border-right: 25px solid transparent;
                border-bottom: 20px solid $black500;
                transform: translate(50% , 0%);
                transition: 0.4s;
                opacity: 0;
                pointer-events: none;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 175%);
                @media (max-width: 1599px) {
                    transform: translate(-50%,34px);
                }
            }
            &::before{
                content: '';
                position: absolute;
                width: 0%;
                height: 1px;
                background: $yellow;
                bottom: -4.67px;
                left: 0;
                transition: $transition;
            }
            &:hover{
                &::before{
                    width: 100%;
                }
                &::after{
                    opacity: 1;
                }
            }
            a {
                @include fontTemplate(1.8rem, 1, #eeeeee, $medium, $raleway);
                transition: $transition;
                text-decoration: none;
                color: #eeeeee !important;
            }

            .arrow {
                transition: $transition;
            }

            &:hover {
                a {
                    color: #eee !important;
                }

                .arrow {
                    transform:  rotate(-90deg);
                }

                .header--nav_dropdown {
                    transform: translate(-50%, 100%) scale(1);
                    opacity: 1;
                    pointer-events: all;
                    @media (max-width: 1650px) {
                        transform: translate(-30%,100%) scale(1);
                    }
                }
            }

        }
        &_itemNot{
            &::after{
                display: none;
            }
        }
        &_dropdown {
            position: absolute;
            left: 50%;
            bottom: 0;
            opacity: 0;
            transform: translate(-50%, 100%) ;
            padding-top: 45px;
            transition: $transition;
            pointer-events: none;
            &-list {
                width: 865px;
                height: auto;
                padding: 60px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                position: relative;
                background-color: $black500;
                gap: 140px;
                align-items: start;
            }

            &-item {
                width: 100%;
                display: flex;
                a {
                    padding: 12px 8px;
                    width: 100%;
                    border-bottom: 1px solid #dfe4fd59;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    @include fontTemplate(
                        2rem,
                        1,
                        $white !important,
                        $medium,
                        $raleway
                    );
                    color: #fff !important;
                    transition: $transition;
                    display: grid;
                    grid-template-columns: 1fr 24px;
                    svg{
                        opacity: 0;
                        transition: all 0.3s ease-in;
                    }
                    @media (max-width: 1599px) {
                        font-size: 2rem;
                    }
                    &:hover {
                        svg{
                            opacity: 1;
                        }
                        color: #fff !important;
                        border-bottom-color: #DFE4FD;
                    }
                }
            }
        }
    }

    &--btn-menu {
        display: none;
    }

    &--menu-inside {
        display: none;
        opacity: 0;
    }

    @media (max-width: 1599px) {
        padding: 30px 0px;
    }

    @media (max-width: 1200px) {
        .arrow-seach{
            display: none;
        }
        &--container {
            width: 100%;
            padding: 0 0 0 10vw;
            justify-content: space-between;
            display: flex;
            padding-inline: 2rem !important;
            justify-items: stretch;
        }

        &--logo {
            position: relative;
            z-index: 3;
        }

        &--nav {
            display: none;
        }

        &--btn-menu {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 13px 24px 13px 11px;
            overflow: hidden;
            background: $yellow;
            position: relative;
            box-shadow: 7px 7px 0px $yellow;
            z-index: 2;
            cursor: pointer !important;

            span {
                width: 20px;
                height: 2px;
                background-color: $white;
                transition: 0.4s ease-in-out;
                left: 0;
                display: block;
            }

            &.open span:nth-child(1) {
                transform: translateY(6px) rotate(135deg);
            }
            &.open span:nth-child(2) {
                opacity: 0;
            }
            &.open span:nth-child(3) {
                transform: translateY(-8px) rotate(-135deg);
            }
        }

        &--menu-inside {
            position: fixed;
            z-index: 1;
            inset: 0;
            width: 100%;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            background-color: black;
            padding: 135px 10vw 0px;

            .nav {
                flex: 1;
                &_list {
                    display: flex;
                    flex-direction: column;
                    gap: 35px;
                }

                &_item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    position: relative;
                    a {
                        @include fontTemplate(
                            1.7rem,
                            1,
                            $white,
                            $bold,
                            $raleway
                        );
                        transition: $transition;
                        @media (max-width: 1599px) {
                            font-size: 2rem;
                        }
                    }

                    .arrow {
                        transition: $transition;
                    }

                    &:hover {
                        a {
                            color: $yellow;
                        }
                    }

                    &.active {
                        a {
                            color: $yellow;
                        }
                    }
                }

                &_dropdown {
                    position: absolute;
                    left: 0%;
                    bottom: 0;
                    opacity: 0;
                    transform: translateY(100%);
                    padding-top: 25px;
                    z-index: 100;

                    display: none;
                    opacity: 0;

                    &-list {
                        width: 206px;
                        height: auto;
                        padding: 32px 10px 25px 10px;

                        display: flex;
                        flex-direction: column;
                        gap: 21px;

                        position: relative;
                        background-color: $black;
                        clip-path: polygon(
                            100% 0,
                            100% 83%,
                            83% 100%,
                            0 100%,
                            0 0
                        );

                        &::before {
                            content: "";
                            width: 100%;
                            height: 3px;
                            background: transparent
                                linear-gradient(91deg, #13b9ef 0%, #e548ad 100%)
                                0% 0% no-repeat padding-box;
                            position: absolute;
                            inset: 0;
                        }
                    }

                    &-item {
                        width: 100%;
                        display: flex;
                        a {
                            padding-left: 30px;
                            width: 100%;
                            @include fontTemplate(
                                1.7rem,
                                1,
                                $white !important,
                                $bold,
                                $raleway
                            );
                            transition: $transition;
                            @media (max-width: 1599px) {
                                font-size: 2rem;
                            }
                            &:hover {
                                color: $yellow !important;
                            }
                        }
                    }
                }
            }

            .social-midias {
                position: absolute;
                bottom: 50px;
                left: 50%;
                transform: translateX(-50%);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                z-index: 1000;

                svg {
                    width: auto;
                    max-width: 20px;
                    max-height: 20px;
                    height: auto;
                    object-fit: contain;
                    transition: $transition;
                    path {
                        transition: $transition;
                    }

                    &:hover {
                        fill: $white;
                        path {
                            fill: $white;
                        }
                    }
                }
            }

            .details {
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translate(-57%, 40%);
                width: 212px;
                height: auto;
                object-fit: contain;
            }
        }
    }

    @media (max-width: 699px) {
        &--logo {
            width: 100%;
            object-fit: contain;
        }
    }


    .hamburger{
        display: none;
        @media (max-width: 1200px) {
            display: flex;
            justify-content: flex-end;
            z-index: 3;
        }
        .close{
            display: none;
        }
        .open{
            display: block;
        }
    }
    .hamburger .line{
        width: 50px;
        height: 4.5px;
        background-color: #ecf0f1;
        display: block;
        margin: 8px auto;
        border-radius: 15px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      .hamburger:hover{
        cursor: pointer;
      }

      /* ONE */
        #hamburger-1.is-active{
            .close{
                display: block;
            }
            .open{
                display: none;
            }
        }
        .menu {
            background-color: transparent;
            border: none;
            cursor: pointer;
            display: flex;
            padding: 0;
            z-index: 3;
            display: none;
            @media (max-width: 1200px) {
                display: block;
            }
          }
          .line {
            fill: none;
            stroke: #fff;
            stroke-width: 6;
            transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
              stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
          }
          .line1 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
          }
          .line2 {
            stroke-dasharray: 60 60;
            stroke-width: 6;
          }
          .line3 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
          }
          .opened .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
          }
          .opened .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
            stroke-width: 6;
          }
          .opened .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
          }

        .menu_mobile{
            position: fixed;
            top: 0;
            left: 0;
            width: 100dvw;
            padding-top: 15rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            overflow-y: scroll;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
            padding-left: 93px;
            padding-bottom: 60px;
            gap: 80px;
            display: none;
            opacity: 0;
            transition: $transition;
            &.active{
                display: flex;
                opacity: 1;
            }
            &--list{
                display: flex;
                flex-direction: column;
                gap: 4rem;
            }
            &--link{
                @include fontTemplate(2.8rem, 1, $white, $medium, $raleway, start);
                text-decoration: none;
                a{
                    @include fontTemplate(2.8rem, 1, $white, $medium, $raleway, start);
                    display: flex;
                    align-items: flex-start;
                    gap: 5px;
                }
            }
            &--redes{
                display: flex;
                gap: 2rem;
                flex-direction: column;
                p{
                    @include fontTemplate(1.6rem, 1, $white, $medium, $raleway, start);
                }
                &__box{
                    display: flex;
                    gap: 2rem;
                }
                &__link{

                }
            }

            &--dropdown{
                display: none;
                background: rgba(36, 36, 36, 0.8);
                left: -96px;
                position: relative;
                width: 100vw;
                padding-top: 3.2rem;
                padding-bottom: 3.2rem;
                padding-left: 93px;
                margin-top: 40px;
                padding-right: 28px;
                &.active{
                    display: block;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: -20px;
                    right: 130px;
                    width: 0;
                    height: 0;
                    border-left: 25px solid transparent;
                    border-right: 25px solid transparent;
                    border-bottom: 20px solid rgba(36, 36, 36, 0.8);
                }
                &--img{
                    width: 210px;
                    height: 150px;
                    object-fit: cover;
                    margin-bottom: 20px;
                }
                h2{
                    @include fontTemplate(2.4rem, 3.2rem, $white, $bold, $raleway, start);
                    margin-bottom: 8px;
                }
                p{
                    @include fontTemplate(1.2rem, 1.8rem, #F0F1F7, $medium, $raleway, start);
                    max-width: 205px;
                }
                ul{
                    margin-top: 44px;
                }
                li{
                    @include fontTemplate(1.8rem, 2.8rem, $white, $medium, $raleway, start);
                    height: 52px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(223, 228, 253, 0.15);
                    a{
                        @include fontTemplate(1.8rem, 2.8rem, $white, $medium, $raleway, start);

                    }
                }
            }
        }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // overflow-wrap: anywhere; IF NEED TO BREAK LINE WHEN PUTTING WORDS WITHOUT SPACE LIKE 'TESTETESTETESTETESTE'
}

body {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
    font-weight: normal;
    margin: 0;
}

:focus,
button:focus,
a:focus {
    outline: 0;
}

ol,
ul {
    list-style: none;
    padding: 0;
}

li {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    &:focus,
    &:hover {
        color: inherit;
        text-decoration: none;
        outline: 0;
    }
}

form {
    margin: 0;
}

input:focus,
textarea:focus,
select:focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}

button {
    border: none;
    background-color: transparent;
    padding: 0;
    &:focus,
    &:hover {
        color: inherit;
        text-decoration: none;
        outline: 0;
    }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
html {
  font-size: 62.5%;
}
@media (min-width: 750px) and (max-width: 1399px) {
  html {
    font-size: 54.5%;
  }
}

.raleway {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.form-group {
  position: relative;
  margin: 0;
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

.fixed-logo {
  position: absolute;
  right: 42px;
  top: 242px;
  z-index: 9;
}
.fixed-logo .circle-letters {
  animation: spin 15s linear infinite;
  transform-origin: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 0px;
  background: transparent linear-gradient(180deg, #000000 0%, rgba(1, 5, 15, 0) 100%) 0% 0% no-repeat padding-box;
  z-index: 1000;
  height: fit-content;
  transition: 1s;
  /* ONE */
}
.header.active {
  background: #485CE6 !important;
  left: inherit;
  right: 0;
  width: calc(100% - 25px);
  padding-bottom: 42px;
}
.header.active .header--logo {
  filter: brightness(0) invert(1);
}
.header.activeDown {
  background: #000008;
}
@media (max-width: 1200px) {
  .header--logoLink {
    width: 120px;
  }
}
.header .form-select {
  width: 84px;
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  height: 48px;
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  padding-left: 2rem;
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.5 5.83032L0.5 0.830322H10.5L5.5 5.83032Z' fill='white'/%3e%3c/svg%3e");
  background-position: right 1.75rem center;
}
.header .form-select option {
  background: #242424;
}
@media (max-width: 1200px) {
  .header .form-select {
    width: 71px;
    height: 34px;
    font-size: 1.2rem;
    line-height: 1.4rem;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg width='7.5' height='3.75' viewBox='0 0 7.5 3.75' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.75 3.75L0 0H7.5L3.75 3.75Z' fill='white'/%3e%3c/svg%3e");
    z-index: 3;
    background-position: right 1rem center;
    background-size: auto;
  }
}
@media (max-width: 1200px) {
  .header .language-mobile .form-select {
    width: 71.5px;
    padding: 10px 8px;
  }
  .header .language-mobile .form-select option {
    font-size: 12px;
    line-height: 14px;
  }
}
.header--container {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  width: min(100%, 1539px);
}
@media (max-width: 992px) {
  .header--container {
    gap: 30px;
  }
}
.header--logo {
  width: 100%;
  height: 56px;
  object-fit: contain;
}
.header .wrapper-dropdown {
  display: flex;
  flex-direction: column;
  gap: 21px;
}
.header .service-list {
  max-height: 80vh;
  min-width: 865px;
  gap: 100px;
  grid-template-columns: 300px 1fr;
  width: auto;
}
@media (max-width: 1500px) {
  .header .service-dropdown:hover {
    transform: translate(-33%, 100%) !important;
  }
}
@media (max-width: 1500px) {
  .header .contato-dropdown:hover {
    transform: translate(-82%, 100%) !important;
  }
}
.header .service-wrapper {
  display: grid;
  grid-template-columns: 260px 326px;
  gap: 123px;
}
.header .wrapper-dropdonw li a {
  color: #fff !important;
}
.header .cta-header {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}
.header .cta-header img {
  width: 210px;
  height: 150px;
  object-fit: cover;
  margin-top: 50px;
}
.header .cta-header--title {
  margin-top: 44px;
  margin-bottom: 15px;
  font-size: 4.8rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  text-align: start;
}
.header .cta-header--text {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  margin-bottom: 0;
  margin-top: 20px;
}
@media (max-width: 1599px) {
  .header .cta-header--text {
    font-size: 2rem;
  }
}
.header .cta-header--link {
  font-size: 2rem;
  line-height: 1;
  color: #485CE6 !important;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  text-align: center;
  text-decoration: none;
  padding: 20px 40px;
  margin-top: 44px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  gap: 8px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
  align-items: center;
}
.header .cta-header--link:hover {
  background-image: linear-gradient(118deg, #fff -15%, #dfe4fd 59%, #dfe4fd 59%);
}
.header--nav {
  width: 100%;
}
.header--nav .active::before {
  width: 100%;
}
.header--nav_list {
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 0.5fr 237px 0.5fr;
  justify-content: space-between;
  align-items: center;
}
.header--nav_inside {
  display: flex;
  gap: 40px;
  align-items: center;
}
.header--nav_inside:last-of-type {
  justify-content: end;
}
.header--nav_item {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  height: fit-content;
}
.header--nav_item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 20px solid #242424;
  transform: translate(50%, 0%);
  transition: 0.4s;
  opacity: 0;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 175%);
}
@media (max-width: 1599px) {
  .header--nav_item::after {
    transform: translate(-50%, 34px);
  }
}
.header--nav_item::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  background: #FAD224;
  bottom: -4.67px;
  left: 0;
  transition: 0.4s;
}
.header--nav_item:hover::before {
  width: 100%;
}
.header--nav_item:hover::after {
  opacity: 1;
}
.header--nav_item a {
  font-size: 1.8rem;
  line-height: 1;
  color: #eeeeee;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  transition: 0.4s;
  text-decoration: none;
  color: #eeeeee !important;
}
.header--nav_item .arrow {
  transition: 0.4s;
}
.header--nav_item:hover a {
  color: #eee !important;
}
.header--nav_item:hover .arrow {
  transform: rotate(-90deg);
}
.header--nav_item:hover .header--nav_dropdown {
  transform: translate(-50%, 100%) scale(1);
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 1650px) {
  .header--nav_item:hover .header--nav_dropdown {
    transform: translate(-30%, 100%) scale(1);
  }
}
.header--nav_itemNot::after {
  display: none;
}
.header--nav_dropdown {
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transform: translate(-50%, 100%);
  padding-top: 45px;
  transition: 0.4s;
  pointer-events: none;
}
.header--nav_dropdown-list {
  width: 865px;
  height: auto;
  padding: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  background-color: #242424;
  gap: 140px;
  align-items: start;
}
.header--nav_dropdown-item {
  width: 100%;
  display: flex;
}
.header--nav_dropdown-item a {
  padding: 12px 8px;
  width: 100%;
  border-bottom: 1px solid rgba(223, 228, 253, 0.3490196078);
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 2rem;
  line-height: 1;
  color: #ffffff !important;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  color: #fff !important;
  transition: 0.4s;
  display: grid;
  grid-template-columns: 1fr 24px;
}
.header--nav_dropdown-item a svg {
  opacity: 0;
  transition: all 0.3s ease-in;
}
@media (max-width: 1599px) {
  .header--nav_dropdown-item a {
    font-size: 2rem;
  }
}
.header--nav_dropdown-item a:hover {
  color: #fff !important;
  border-bottom-color: #DFE4FD;
}
.header--nav_dropdown-item a:hover svg {
  opacity: 1;
}
.header--btn-menu {
  display: none;
}
.header--menu-inside {
  display: none;
  opacity: 0;
}
@media (max-width: 1599px) {
  .header {
    padding: 30px 0px;
  }
}
@media (max-width: 1200px) {
  .header .arrow-seach {
    display: none;
  }
  .header--container {
    width: 100%;
    padding: 0 0 0 10vw;
    justify-content: space-between;
    display: flex;
    padding-inline: 2rem !important;
    justify-items: stretch;
  }
  .header--logo {
    position: relative;
    z-index: 3;
  }
  .header--nav {
    display: none;
  }
  .header--btn-menu {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 13px 24px 13px 11px;
    overflow: hidden;
    background: #FAD224;
    position: relative;
    box-shadow: 7px 7px 0px #FAD224;
    z-index: 2;
    cursor: pointer !important;
  }
  .header--btn-menu span {
    width: 20px;
    height: 2px;
    background-color: #ffffff;
    transition: 0.4s ease-in-out;
    left: 0;
    display: block;
  }
  .header--btn-menu.open span:nth-child(1) {
    transform: translateY(6px) rotate(135deg);
  }
  .header--btn-menu.open span:nth-child(2) {
    opacity: 0;
  }
  .header--btn-menu.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-135deg);
  }
  .header--menu-inside {
    position: fixed;
    z-index: 1;
    inset: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: black;
    padding: 135px 10vw 0px;
  }
  .header--menu-inside .nav {
    flex: 1;
  }
  .header--menu-inside .nav_list {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .header--menu-inside .nav_item {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  .header--menu-inside .nav_item a {
    font-size: 1.7rem;
    line-height: 1;
    color: #ffffff;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    text-align: start;
    transition: 0.4s;
  }
}
@media (max-width: 1200px) and (max-width: 1599px) {
  .header--menu-inside .nav_item a {
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_item .arrow {
    transition: 0.4s;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_item:hover a {
    color: #FAD224;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_item.active a {
    color: #FAD224;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_dropdown {
    position: absolute;
    left: 0%;
    bottom: 0;
    opacity: 0;
    transform: translateY(100%);
    padding-top: 25px;
    z-index: 100;
    display: none;
    opacity: 0;
  }
  .header--menu-inside .nav_dropdown-list {
    width: 206px;
    height: auto;
    padding: 32px 10px 25px 10px;
    display: flex;
    flex-direction: column;
    gap: 21px;
    position: relative;
    background-color: 900;
    clip-path: polygon(100% 0, 100% 83%, 83% 100%, 0 100%, 0 0);
  }
  .header--menu-inside .nav_dropdown-list::before {
    content: "";
    width: 100%;
    height: 3px;
    background: transparent linear-gradient(91deg, #13b9ef 0%, #e548ad 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    inset: 0;
  }
  .header--menu-inside .nav_dropdown-item {
    width: 100%;
    display: flex;
  }
  .header--menu-inside .nav_dropdown-item a {
    padding-left: 30px;
    width: 100%;
    font-size: 1.7rem;
    line-height: 1;
    color: #ffffff !important;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    text-align: start;
    transition: 0.4s;
  }
}
@media (max-width: 1200px) and (max-width: 1599px) {
  .header--menu-inside .nav_dropdown-item a {
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .nav_dropdown-item a:hover {
    color: #FAD224 !important;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .social-midias {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    z-index: 1000;
  }
  .header--menu-inside .social-midias svg {
    width: auto;
    max-width: 20px;
    max-height: 20px;
    height: auto;
    object-fit: contain;
    transition: 0.4s;
  }
  .header--menu-inside .social-midias svg path {
    transition: 0.4s;
  }
  .header--menu-inside .social-midias svg:hover {
    fill: #ffffff;
  }
  .header--menu-inside .social-midias svg:hover path {
    fill: #ffffff;
  }
}
@media (max-width: 1200px) {
  .header--menu-inside .details {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-57%, 40%);
    width: 212px;
    height: auto;
    object-fit: contain;
  }
}
@media (max-width: 699px) {
  .header--logo {
    width: 100%;
    object-fit: contain;
  }
}
.header .hamburger {
  display: none;
}
@media (max-width: 1200px) {
  .header .hamburger {
    display: flex;
    justify-content: flex-end;
    z-index: 3;
  }
}
.header .hamburger .close {
  display: none;
}
.header .hamburger .open {
  display: block;
}
.header .hamburger .line {
  width: 50px;
  height: 4.5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header .hamburger:hover {
  cursor: pointer;
}
.header #hamburger-1.is-active .close {
  display: block;
}
.header #hamburger-1.is-active .open {
  display: none;
}
.header .menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  z-index: 3;
  display: none;
}
@media (max-width: 1200px) {
  .header .menu {
    display: block;
  }
}
.header .line {
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.header .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.header .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.header .opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.header .opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.header .opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.header .menu_mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  padding-top: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-left: 93px;
  padding-bottom: 60px;
  gap: 80px;
  display: none;
  opacity: 0;
  transition: 0.4s;
}
.header .menu_mobile.active {
  display: flex;
  opacity: 1;
}
.header .menu_mobile--list {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.header .menu_mobile--link {
  font-size: 2.8rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  text-decoration: none;
}
.header .menu_mobile--link a {
  font-size: 2.8rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.header .menu_mobile--redes {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.header .menu_mobile--redes p {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
}
.header .menu_mobile--redes__box {
  display: flex;
  gap: 2rem;
}
.header .menu_mobile--dropdown {
  display: none;
  background: rgba(36, 36, 36, 0.8);
  left: -96px;
  position: relative;
  width: 100vw;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  padding-left: 93px;
  margin-top: 40px;
  padding-right: 28px;
}
.header .menu_mobile--dropdown.active {
  display: block;
}
.header .menu_mobile--dropdown::after {
  content: "";
  position: absolute;
  top: -20px;
  right: 130px;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 20px solid rgba(36, 36, 36, 0.8);
}
.header .menu_mobile--dropdown--img {
  width: 210px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}
.header .menu_mobile--dropdown h2 {
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #ffffff;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  text-align: start;
  margin-bottom: 8px;
}
.header .menu_mobile--dropdown p {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #F0F1F7;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  max-width: 205px;
}
.header .menu_mobile--dropdown ul {
  margin-top: 44px;
}
.header .menu_mobile--dropdown li {
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  height: 52px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(223, 228, 253, 0.15);
}
.header .menu_mobile--dropdown li a {
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
}

#footer {
  padding-top: 75px;
  background: #000;
  padding-inline: 10rem;
  overflow: hidden;
  /* The checkWrapper */
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
@media (max-width: 1100px) {
  #footer {
    padding-top: 40px;
    padding-inline: 20px;
  }
}
#footer .logo-center {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 27px;
}
@media (max-width: 1100px) {
  #footer .logo-center {
    width: 50%;
    justify-content: start;
    margin-top: 60px;
    margin-bottom: 20px;
  }
  #footer .logo-center img {
    height: 38px;
    width: 165px;
  }
}
@media (max-width: 1100px) and (max-width: 380px) {
  #footer .logo-center img {
    height: 30px;
    width: 126px;
  }
}
#footer .logo-center .selo {
  position: absolute;
  right: 0;
}
#footer .logo-center .selo img {
  width: 250px;
  height: auto;
}
@media (max-width: 1100px) {
  #footer .logo-center .selo {
    right: -40%;
  }
  #footer .logo-center .selo img {
    width: 140px;
  }
}
@media (max-width: 450px) {
  #footer .logo-center .selo {
    right: calc(-100% - 45px);
    top: calc(100% + 20px);
  }
}
#footer .footer_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 322px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
@media (max-width: 1100px) {
  #footer .footer_grid {
    grid-template-columns: 1fr;
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 60px;
  }
}
#footer .footer_grid--title {
  font-size: 1.8rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  grid-column: 1/3;
}
@media (max-width: 1100px) {
  #footer .footer_grid--title {
    font-size: 18px;
    line-height: 21.13px;
  }
}
#footer .footer_grid--link {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  transition: 0.4s;
  position: relative;
  text-decoration: none;
  width: fit-content;
}
@media (max-width: 1100px) {
  #footer .footer_grid--link {
    font-size: 14px;
    line-height: 16.44px;
  }
}
#footer .footer_grid--link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background: #FAD224;
  bottom: 0;
  left: 0;
  transition: 0.4s;
}
#footer .footer_grid--link:hover::after {
  width: 100%;
}
#footer .footer_grid--colun-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7rem;
  row-gap: 2.2rem;
}
@media (max-width: 1100px) {
  #footer .footer_grid--colun-1 {
    gap: 61px;
    row-gap: 20px;
  }
}
#footer .footer_grid--colun-1 div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media (max-width: 1100px) {
  #footer .footer_grid--colun-1 div {
    gap: 12px;
  }
}
#footer .footer_grid--colun-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7rem;
  row-gap: 2.2rem;
}
@media (max-width: 1100px) {
  #footer .footer_grid--colun-2 {
    gap: 61px;
    row-gap: 20px;
  }
}
#footer .footer_grid--colun-2 div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media (max-width: 1100px) {
  #footer .footer_grid--colun-2 div {
    gap: 12px;
  }
}
#footer .footer_grid--colun-3 {
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
}
@media (max-width: 1100px) {
  #footer .footer_grid--colun-3 {
    gap: 61px;
    row-gap: 20px;
  }
}
#footer .footer_grid--colun-3 div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media (max-width: 1100px) {
  #footer .footer_grid--colun-3 div {
    gap: 20px;
  }
}
#footer .footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 20px;
}
#footer .footer_bottom--text {
  font-size: 1.4rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
}
@media (max-width: 1100px) {
  #footer .footer_bottom--text {
    line-height: 16.44px;
  }
  #footer .footer_bottom--text:last-of-type {
    display: none;
  }
}
#footer .footer_bottom .weecom {
  transition: 0.4s;
}
#footer .footer_bottom .weecom svg {
  transition: 0.4s;
}
@media (max-width: 1100px) {
  #footer .footer_bottom .weecom svg {
    opacity: 0.5;
  }
}
#footer .footer_bottom .weecom svg path {
  fill: #F5EEE5;
}
#footer .footer_bottom .weecom:hover svg {
  opacity: 1;
}
#footer .footer_bottom .weecom:hover svg path {
  fill: #FAD224;
}
#footer .footer_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 1100px) {
  #footer .footer_top {
    flex-direction: column;
    justify-content: start;
  }
}
#footer .footer_top--title {
  font-size: 4.6em;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: start;
  letter-spacing: -2%;
}
@media (max-width: 1100px) {
  #footer .footer_top--title {
    font-size: 30px;
    line-height: 36px;
  }
}
#footer .footer_top--sub {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
}
@media (max-width: 1100px) {
  #footer .footer_top--sub {
    font-size: 12px;
    line-height: 28px;
  }
}
#footer .footer_top--input {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  background: transparent;
  display: flex;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
}
@media (max-width: 1100px) {
  #footer .footer_top--input {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }
}
#footer .footer_top--input input {
  padding-inline: 8px;
  width: 100%;
  border: 1px solid #ffffff;
  background: transparent;
  max-width: 504px;
  height: 43px;
  border-radius: 0;
}
@media (max-width: 1100px) {
  #footer .footer_top--input input {
    max-width: 100%;
    height: 41px;
  }
}
#footer .footer_top--input input, #footer .footer_top--input input::placeholder {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
}
#footer .footer_top--input button {
  font-size: 1.6rem;
  line-height: 1;
  color: #242424;
  font-weight: 700;
  font-family: "Rex Bold", sans-serif;
  text-align: start;
  text-decoration: none;
  background: #FAD224;
  height: 43px;
  padding-inline: 25px;
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  transition: 0.4s;
  text-transform: uppercase;
  text-wrap: nowrap;
}
@media (max-width: 1100px) {
  #footer .footer_top--input button {
    width: 100%;
  }
}
#footer .footer_top--input button:hover {
  color: #FAD224;
  background: #242424;
}
#footer .footer_top--redes {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media (max-width: 1100px) {
  #footer .footer_top--redes {
    position: absolute;
    right: 20px;
    top: 130%;
  }
}
#footer .footer_top--redes span {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
}
@media (max-width: 1100px) {
  #footer .footer_top--redes span {
    display: none;
  }
}
#footer .footer_top--redes div {
  display: flex;
  gap: 31px;
}
@media (max-width: 1100px) {
  #footer .footer_top--redes div {
    gap: 20px;
  }
}
#footer .footer_top--redes div .change {
  transition: 0.4s;
}
@media (max-width: 1100px) {
  #footer .footer_top--redes div a svg {
    width: 20px;
    height: 20px;
  }
}
#footer .footer_top--redes div a:hover .change {
  fill: #FAD224;
}
#footer .checkWrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.1rem;
  line-height: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-align: start;
  padding-top: 8px;
  margin-bottom: 12px;
}
#footer .checkWrapper a {
  color: #FAD224;
}
@media (max-width: 1100px) {
  #footer .checkWrapper {
    padding-top: 0;
    margin-bottom: 0;
  }
}
#footer .checkWrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#footer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
}
#footer .checkWrapper:hover input ~ .checkmark {
  background-color: #ccc;
}
#footer .checkWrapper input:checked ~ .checkmark {
  background-color: #FAD224;
}
#footer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
#footer .checkWrapper input:checked ~ .checkmark:after {
  display: block;
}
#footer .checkWrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.gscrollbar-fixer {
  margin: 0 !important;
  padding: 0 !important;
}

html, body {
  scrollbar-width: auto;
  scrollbar-color: #FAD224 #242424;
}
html::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 5px;
}
html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background: #FAD224;
}
html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: #242424;
  border-radius: 0;
  border: 3px solid #242424;
}

.scroll {
  width: 100%;
}

.text-marquee {
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  z-index: 50;
}
.text-marquee .text-single {
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  will-change: transform;
  overflow: hidden;
}
.text-marquee .text {
  padding: 0;
  display: inline-block;
  margin-right: 2rem;
  font-size: 8rem;
  line-height: 1.3;
  color: #fff;
  font-weight: 500;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: left;
  text-shadow: 2px 2px 0 #242424, -2px 2px 0 #242424, -2px -2px 0 #242424, 2px -2px 0 #242424;
}
@media (max-width: 599px) {
  .text-marquee .text {
    font-size: 5rem;
    line-height: 1.35;
    text-shadow: 1.2px 1.2px 0 #242424, -1.2px 1.2px 0 #242424, -1.2px -1.2px 0 #242424, 1.2px -1.2px 0 #242424;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 60px;
}
@media (max-width: 992px) {
  .pagination {
    margin-top: 44px;
  }
}
.pagination--arrow svg {
  width: 30px;
  height: 30px;
}
.pagination--arrow svg:hover path {
  fill: #FAD224;
}
.pagination--arrow svg path {
  fill: #282B37;
  transition: all 0.3s ease-in-out;
}
.pagination--arrow.prev {
  rotate: 90deg;
}
.pagination--arrow.next {
  rotate: -90deg;
}
.pagination--nav ul {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination--nav ul .btn-page {
  display: grid;
  place-items: center;
  background-color: #f5f5f5;
  color: #576370;
  font-size: 17px;
  font-weight: 600;
  width: 40px;
  aspect-ratio: 1;
  transition: all 0.3s ease-in-out;
}
.pagination--nav ul .btn-page.currentPage {
  background-color: #fad224;
  color: #282B37;
}
.pagination--nav ul .btn-page.currentPage:hover {
  background-color: #fad224;
}
.pagination--nav ul .btn-page:hover {
  background-color: #EEEFF1;
}

/*------------------------- Containers -----------------------*/
.termsfeed-com---nb-simple {
  left: inherit;
  right: 20vw;
  bottom: 2vh;
  transition: 0.4s;
}

/*-------------------------TEXTS -----------------------*/
/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree {
  background: #FAD224;
  color: #1e1e1e;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-dark .cc-nb-okagree:hover {
  background-color: #FAD224;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #1e1e1e;
  background: linear-gradient(141deg, #FAD224 31.89%, transparent) #FAD224 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border: 1px solid #1e1e1e;
}
.termsfeed-com---palette-dark .cc-nb-reject:hover {
  background-color: #FAD224;
}

.termsfeed-com---palette-dark .cc-nb-changep {
  color: #1e1e1e;
  background: linear-gradient(141deg, #FAD224 31.89%, transparent) #FAD224 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border: 1px solid #1e1e1e;
}

.termsfeed-com---palette-dark .cc-nb-okagree {
  color: #1e1e1e;
  background: linear-gradient(141deg, #FAD224 31.89%, transparent) #FAD224 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border: 1px solid #1e1e1e;
}

.termsfeed-com---nb-simple {
  bottom: 2vh;
  border: 0.2px solid #FAD224;
  transition: 0.4s;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.termsfeed-com---nb-simple::after {
  content: "";
  height: 3px;
  background-color: #FAD224;
  position: absolute;
  left: 0;
  bottom: 0;
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.show--error input {
  color: red !important;
  border: 1px solid red !important;
}
.show--error input::placeholder {
  color: red !important;
}
.show--error textarea {
  color: red !important;
  border: 1px solid red !important;
}
.show--error textarea::placeholder {
  color: red !important;
}
.show--error select {
  color: red !important;
  border: 1px solid red !important;
}
.show--error select::placeholder {
  color: red !important;
}
.show--error .checkmark {
  color: red !important;
  border: 1px solid red !important;
}
.show--error .checkmark::placeholder {
  color: red !important;
}
.show--error .error--message {
  display: block;
  color: red !important;
}

.error--message {
  display: none;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: 900;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.error-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: 1;
  width: min(819px, 70%);
  height: 718px;
  background-color: #242424;
  padding-inline: 20px;
  display: grid;
  place-items: center;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 32px;
  right: 32px;
}
.success-message-forms--container #btn--close-success-message .bi {
  font-size: 32px;
  color: #fff;
}
.success-message-forms--container .wrapper {
  max-width: 434px;
  text-align: center;
}
.success-message-forms--container .wrapper > img {
  width: 80px;
  aspect-ratio: 1;
  margin-inline: auto;
}
.success-message-forms--container .wrapper .title {
  margin-block: 20px 30px;
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  clip-path: polygon(0 8%, 72% 8%, 75% 0, 100% 0, 100% 100%, 19% 100%, 16% 92%, 0 92%);
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, 900);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}